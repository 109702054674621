import React from 'react'

import banner from '../images/banner.png'

import img from '../images/img.png'

const Home = () => {
    return (
        <>
            <div className='overflow-hidden'>

                <div className='w-full'>
                    <img src={banner} className='w-full h-screen fixed top-0 -z-20 object-cover' alt="" />
                </div>

                {/* ------------------------ header section -------------------- */}

                <section>
                    <div className=' font-[poppins]'>


                        <div className=' mt-12 md:mt-20  top-12 md:top-16 w-full'>

                            <div className='w-full px-4 md:w-[80%] mx-auto flex flex-col md:flex-row items-center gap-y-4'>

                                <div className='w-full md:w-[50%] mx-auto'>

                                    <div>
                                        <div className='mb-4 md:mb-16 w-fit'>
                                            <h3 className='overflow-hidden font-bold text-[30px] md:text-[45px]   font-[Bebas Neue] mb-2 md:mb-4'>Fueling Your Brand's  <span className='    '></span> </h3>

                                       
                                            <h1 class="text-[#3d8986]  animate-typing overflow-hidden tracking-[1px] md:tracking-[3px] whitespace-nowrap border-r-4 border-r-white pr-5 text-[30px] md:text-[50px]   font-bold">Success! ....</h1>
                                         </div>


                                        <p className=' mt-6 md:mt-4  text-[15px] md:text-[20px] leading-6 md:leading-8 font-specialElite text-gray-800 font-bold'>Our new website will showcase our strategies for impactful advertising.  </p>

                                        <h2 className='px-5 py-2 rounded-md text-white bg-[#50a19e] uppercase tracking-[1px] w-fit mt-8 font-[poppins] font-semibold text-[12px] md:text-[16px] border-2 border-gray-200'>Stay tuned!</h2>

                                    </div>

                                    <div className='mt-6  md:mt-14 flex items-center gap-x-4 md:gap-x-8'>
                                        {/* <ScrollLink to="download" smooth={true} duration={500}>
                                            <button className='shadow-lg drop-shadow-lg hover:scale-105 duration-300 border md:border-2 text-[#6c2788] hover:bg-green-900 hover:text-white border-white  rounded-lg px-3 md:px-5 py-1.5 md:py-2  font-semibold text-[14px] md:text-[16px] font-[roboto]  flex items-center gap-x-2'><span><MdDownload className='text-[18px]' /></span> Buy now</button>
                                        </ScrollLink> */}

                                        {/* <a href="https://api.whatsapp.com/send?phone=9884683888" target="_blank" rel="noopener noreferrer" className="">
                                            <button className='shadow-lg drop-shadow-lg hover:scale-105 duration-300 border md:border-2 text-[#6c2788] hover:bg-green-900 hover:text-white border-white  rounded-lg px-3 md:px-5 py-1.5 md:py-2  font-semibold text-[14px] md:text-[16px] font-[roboto]  flex items-center gap-x-2'><span><MdVideoSettings className='text-[18px]' /></span> Get Demo</button>
                                        </a> */}

                                        {/* a Quote! */}
                                    </div>
                                </div>

                                <div className=' w-full md:w-[50%] mx-auto'>

                                    <div className='flex justify-center items-center w-full'>
                                        <img src={img} className=' w-full  drop-shadow-lg object-cover flex justify-center items-center' alt="" />
                                    </div>

                                </div>


                            </div>

                        </div>

                    </div>
                </section>


            </div>

        </>
    )
}

export default Home