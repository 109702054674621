import React from 'react'
import { useState, useEffect } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';

import { FaWhatsapp } from "react-icons/fa";



// import logo from '../images/64.png'
import logo from '../images/logo/vishnu_ads_logo.png'
import black from '../images/logo/vishnu_ads_logo.png'



const Header = () => {

    // State to manage the navbar's visibility
    const [nav, setNav] = useState(false);


    // Toggle function to handle the navbar's display
    const handleNav = () => {
        setNav(!nav);
    };



    //color change
    const [navbarBg, setNavbarBg] = useState('bg-gray-200');


    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setNavbarBg('bg-gray-100 shadow-md ');
            } else {
                setNavbarBg('bg-gray-200');
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Remove event listener on cleanup
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    function topPage() {
        window.scroll(0, 0)
    }

    //mobile view click the link hide the navbar

    const [hide, setHide] = useState(true)

    // function clicklink() {
    //     setHide(false)
    // }


    return (
        <>


            {/* -------- second navbar ------- */}

            <nav className={` w-full sticky top-0 z-30  py-4  shadow-sm shadow-gray-400   md:py-5 ${navbarBg} `}>
                <div className="max-w-full mx-auto font-kumbh  ">
                    {/* Logo */}
                    <div className='flex justify-between items-center px-4 sm:px-9 w-full xl:w-[90%] mx-auto'>


                        <div className='w-full md:w-fit'>
                            <Link to='/' onClick={topPage}>
                                <img src={logo} className=' w-16 sm:w-16 md:w-24 object-cover' alt="" />
                            </Link>
                        </div>


                        {/* Desktop Navigation */}
                        <ul className='hidden lg:flex w-fit font-semibold gap-x-4 xl:gap-x-10' >

                            {/* 
                            <li className='  cursor-pointer duration-300 text-white  text-[12px] xl:text-[14px] ' >
                                <Link to="/" smooth={true} duration={500} onClick={topPage}>
                                    <p className="relative group flex items-center">
                                        <button class="hover:text-white/50  focus:text-red-600 tracking-[1px] font-semibold">Home</button>
                                    </p>
                                </Link>
                            </li>


                            <li className='  cursor-pointer duration-300 text-white  text-[12px] xl:text-[14px] ' >
                                <Link to="about" smooth={true} duration={500} onClick={topPage}>
                                    <p className="relative group flex items-center">
                                        <button class="hover:text-white/50  focus:text-red-600 tracking-[1px] font-semibold">About Us</button>
                                    </p>
                                </Link>
                            </li>

                            <li className='  cursor-pointer duration-300 text-white  text-[12px] xl:text-[14px] ' >
                                <Link to="admission" smooth={true} duration={500} onClick={topPage}>
                                    <p className="relative group flex items-center">
                                        <button class="hover:text-white/50  focus:text-red-600 tracking-[1px] font-semibold">Admission</button>
                                    </p>
                                </Link>
                            </li> */}

                            {/* <li className='  cursor-pointer duration-300 text-white  text-[12px] xl:text-[14px]' >

                                <ScrollLink to="fourteen" smooth={true} duration={500} onClick={topPage}>
                                    <p className="relative group flex items-center">
                                        <button class=" hover:text-white/50  focus:text-red-600 tracking-[1px] font-semibold">14 Crafts</button>
                                    </p>
                                </ScrollLink>

                            </li> */}


                        </ul>

                        <div className='cursor-pointer ml-20 mt-2 md:mt-0 md:ml-0 w-full md:w-fit'>
                            {/* <a href="https://api.whatsapp.com/send?phone=9884683888" target="_blank" rel="noopener noreferrer" className="">

                                <div className=' w-6 h-6 md:h-8 md:w-8 bg-green-600 rounded-full duration-300 shadow-sm drop-shadow-md shadow-gray-100 hover:shadow-green-200 hover:shadow-sm hover:drop-shadow-md hover:scale-105 flex  justify-center items-center  '>
                                    <FaWhatsapp className=' rounded-full text-[16px] md:text-[21px]  text-white' />
                                </div>
                            </a> */}
                            <a href="https://api.whatsapp.com/send?phone=9884683888" target="_blank" rel="noopener noreferrer" className="font-[poppins]">
                                <button className='font-semibold border border-[#6ebcba] hover:text-white md:tracking-[1px] px-4 md:px-6 py-2 md:py-2.5 uppercase rounded-md text-gray-50 hover:border hover:border-gray-200 bg-[#3d8986]  hover:bg-[#8663a1] duration-300  text-[12px] md:text-[14px]'>Enquire Now</button>
                            </a>
                        </div>


                        {/* Mobile Navigation Icon */}
                        {/* <div onClick={handleNav} className='block lg:hidden'>
                            {nav ? <AiOutlineClose className='w-16 sm:w-16 md:w-20 text-gray-50' /> : <AiOutlineMenu className='w-16 sm:w-16 md:w-20 text-gray-50' />}
                        </div> */}

                        {/* Mobile Navigation Menu */}
                        {
                            hide &&
                            <ul
                                className={
                                    nav
                                        ? 'fixed lg:hidden left-0 top-0 w-[60%] h-[100%] border-r  bg-gray-50 ease-in-out duration-500'
                                        : 'ease-in-out w-[60%] duration-500 fixed top-0 bottom-0 left-[-100%]'
                                }
                            >

                                {/* Mobile Logo */}

                                <Link to='/' onClick={topPage} className='w-full' >
                                    <img src={black} className=' w-36 sm:w-40  pt-10  ml-10' alt="" onClick={handleNav} />
                                </Link>



                                {/* Mobile Navigation Items */}

                                {/* <li className=' ml-10 cursor-pointer duration-300 text-gray-700  text-[13px] mt-8' >
                                    <ScrollLink to="about" smooth={true} duration={500} onClick={topPage}>
                                        <p className="relative group flex items-center">
                                            <button class="font-semibold tracking-[.02em]  hover:text-white/50  focus:text-red-600" onClick={handleNav}>About Us</button>
                                        </p>
                                    </ScrollLink>
                                </li>

                                <li className=' ml-10 cursor-pointer duration-300 text-gray-500  text-[13px] mt-8' >
                                    <ScrollLink to="fourteen" smooth={true} duration={500} onClick={topPage}>

                                        <p className="relative group flex items-center">
                                            <button class="font-semibold tracking-[.02em]  hover:text-white/50  focus:text-red-600" onClick={handleNav}>Fourteen Crafts</button>
                                        </p>
                                    </ScrollLink >

                                </li>

                                <li className=' ml-10 cursor-pointer duration-300 text-gray-500  text-[13px] mt-8' >
                                    <ScrollLink to="course" smooth={true} duration={500} onClick={topPage}>
                                        <p className="relative group flex items-center">
                                            <button class="font-semibold  tracking-[.02em]  hover:text-white/50  focus:text-red-600" onClick={handleNav}>Courses</button>
                                        </p>
                                    </ScrollLink>
                                </li>

                                <li className=' ml-10 cursor-pointer duration-300 text-gray-500  text-[13px] mt-8' >
                                    <ScrollLink to="mentors" smooth={true} duration={500} onClick={topPage}>

                                        <p className="relative group flex items-center">
                                            <button class="font-semibold  tracking-[.02em]  hover:text-white/50  focus:text-red-600" onClick={handleNav}>Mentors</button>
                                        </p>
                                    </ScrollLink>
                                </li>

                                <li className=' ml-10 cursor-pointer duration-300 text-gray-500  text-[13px] mt-8' >
                                    <ScrollLink to="contact" smooth={true} duration={500} onClick={topPage}>

                                        <p className="relative group flex items-center">
                                            <button class="font-semibold  tracking-[.02em]  hover:text-white/50  focus:text-red-600" onClick={handleNav}>Contact</button>
                                        </p>
                                    </ScrollLink>
                                </li> */}
                            </ul>
                        }

                    </div>
                </div>
            </nav >

        </>
    )
}

export default Header